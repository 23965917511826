<template>
  <div :class="isqpisboot ? 'wbobye': ''">
    <div :style="isqpisboot ? 'position: fixed;right: 10px;top: 5px z-index: 1000;': 'position: fixed; top: 55px;right: 10px; z-index: 1000;'">
      <el-button style="text-align: right;" size="mini"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h4 style="text-align: center;">{{ $t('All.检验PASS单分包') }}</h4>
    <div class="formclass">
      <el-form ref="elForm"  label-position="left" :model="formData" size="medium" label-width="90px">
      <el-form-item :label="$t('All.打印单号')">
        {{formData.Serialcode}}
      </el-form-item>
      <el-form-item :label="$t('All.操作日期')">
        {{formData.Date}}
      </el-form-item>
      <el-form-item :label="$t('All.状态')">
        <el-tag :type="formData.State === 0?'success':'danger'" effect="dark">{{ formData.State === 0?$t('All.有效'):$t('All.失效') }}</el-tag>
      </el-form-item>
      <el-form-item :label="$t('All.检验日期')">
        {{formData.Time}}
      </el-form-item>
      <el-form-item :label="$t('All.料号')">
        {{formData.Item_No}}
      </el-form-item>
      <el-form-item :label="$t('All.采购单')">
        {{formData.Order_Number}}
      </el-form-item>
      <el-form-item :label="$t('All.供应商')">
        {{formData.Supplier}}
      </el-form-item>
      <el-form-item :label="$t('All.总数量')">
        {{formData.Warehousing}}
      </el-form-item>
      <el-form-item :label="$t('All.箱') +'/'+ $t('All.袋')">
        {{formData.Box}}
      </el-form-item>
      <el-form-item :label="$t('All.数量')">
        {{formData.Boxquantity}}
      </el-form-item>
      <el-form-item :label="$t('All.拆分次数')">
        {{formData.Count}}
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in Boxlist"
        :label="$t('All.数量')"
        :key="index"
      >
        <el-input v-model="domain.Boxquantity" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" style="width:100px;" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.张数')}}:</span><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="domain.Box" style="width:100px;" autocomplete="off"></el-input><el-button v-show="index + 1 === Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button><el-button v-show="Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
      </el-form-item>
      <el-form-item v-if="!isyl" :label="$t('All.打印机')">
          <el-select v-model="Printname" value-key="Id" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item">
          </el-option>
          </el-select>
        </el-form-item>
      <el-form-item v-if="!isyl" size="large">
        <el-button type="success" @click="lingchufun">{{ $t('All.全部领出') }}</el-button>
        <el-button type="primary" style="margin-left: 50px;" @click="NewTracefenbao" >{{ $t('All.提交') }}</el-button>
      </el-form-item>
    </el-form>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      formData: {},
      isqpisboot: false,
      isyl: false,
      Boxlist: [
        {
          Box: 1,
          Boxquantity: 1
        }
      ],
      Printname: ''
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  computed: {},
  watch: {},
  created () {
    this.Subcontractset(this.$store.state.Qcrode)
  },
  mounted () {},
  methods: {
    ...mapMutations(['TabComponentFun']),
    addDomainbut () {
      var zong = 0
      this.Boxlist.forEach(element => {
        zong = this.$PublicJs.add(zong, this.$PublicJs.mul(element.Box, element.Boxquantity))
      })
      var shengyu = this.$PublicJs.sub(this.formData.Boxquantity, zong)
      if (shengyu <= 0) return this.$message.error(this.$t('All.数量已不足'))
      this.Boxlist.push({
        Box: 1,
        Boxquantity: shengyu
      })
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.Boxlist.indexOf(item)
          if (index !== -1) {
            this.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    async NewTracefenbao () {
      if (this.Printname.length < 1) {
        return this.$message.error(this.$t('All.请选择打印机后在进行打印'))
      }
      var zong = 0
      this.Boxlist.forEach(element => {
        zong = this.$PublicJs.add(zong, this.$PublicJs.mul(element.Box, element.Boxquantity))
      })
      if (parseFloat(zong) !== parseFloat(this.formData.Boxquantity)) return this.$message.error(this.$t('All.分包数量与数量不符合'))
      const loadingInstance = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const postdata = {
        Boxlist: this.Boxlist,
        Traceprin: this.formData,
        PrinterData: this.Printname,
        UserData: this.$store.state.Login
      }
      var isbool = 0
      this.Boxlist.forEach(element => {
        isbool += parseInt(element.Box)
      })
      if (isbool > 10) {
        loadingInstance.close()
        this.$confirm(`${this.$t('All.确认要打印')}${isbool}${this.$t('All.张')}？`, this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(async () => {
            const loadingInstance1 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
            const { data: res } = await this.$http.post('/api/Trace/NewTracefenbao', postdata)
            if (res.status !== 200) {
              loadingInstance1.close()
              return this.$message.error(res.msg)
            }
            loadingInstance1.close()
            this.$message.success(res.msg)
            this.TabComponentFun('Subcontract')
            this.RemoveTab(this.$t('All.检验PASS单分包'))
            this.Addtab(this.$t('All.二维码'), 'Qecode')
          })
          .catch(() => {
          })
        return
      }
      const { data: res } = await this.$http.post('/api/Trace/NewTracefenbao', postdata)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      this.TabComponentFun('Subcontract')
      this.RemoveTab(this.$t('All.检验PASS单分包'))
      this.Addtab(this.$t('All.二维码'), 'Qecode')
    },
    async Subcontractset (row) {
      const { data: res } = await this.$http.post(`/api/Trace/Subcontractset?Serialcode=${row}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData = res.response
      if (this.formData.State === 1) {
        this.isyl = true
        return this.$message.error(this.$t('All.二维码已失效'))
      }
      this.$message.success(res.msg)
    },
    async lingchufun () {
      const postdata = {
        Id: this.formData.Id,
        UserData: this.$store.state.Login
      }
      const { data: res } = await this.$http.post('/api/Semifinished/PASSlingchu', postdata)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.$store.state.TabComponent = 'Qecode'
      this.RemoveTab(this.$t('All.检验PASS单分包'))
      this.Addtab(this.$t('All.二维码'), 'Qecode')
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin: 0 auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 400px;
}
.formclass{
  width: 400px;
  margin: 0 auto;
}
</style>
